<template>
  <div class="container-fluid">
    <div class="row page-titles">
      <div class="col-md-5 align-self-center">
        <h4 class="text-themecolor">{{$parent.pageTitle}}</h4>
      </div>
      <div class="col-md-7 align-self-center text-right">
        <div class="d-flex justify-content-end align-items-center">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <router-link :to="{name:'Home'}">Home</router-link>
            </li>
            <li class="breadcrumb-item active">{{$parent.pageTitle}}</li>
          </ol>
          <template>
            <router-link v-if="$parent.isList && !isAdd" :to="{name:$route.name, params:{pageSlug:'add'}}"
              class="btn btn-rounded btn-info d-lg-block m-l-15">
              <i class="fa fa-plus-circle"></i> Add New Blog
            </router-link>
            <router-link v-else-if="!$parent.isList" :to="{name:$route.name}"
              class="btn btn-rounded btn-info d-lg-block m-l-15">
              <i class="fas fa-list-ul m-r-15"></i>Blog List
            </router-link>
            <slot name="button"></slot>

          </template>
        </div>
      </div>
    </div>
    <template v-if="isList">
      <ul class="nav nav-tabs customtab" role="tablist">
        <li class="nav-item"> <a class="nav-link active" id="main-nav-dynamic" data-toggle="tab" href="#main" role="tab"
            @click="changeTab('main')"><span class="hidden-sm-up"></span> <span
              class="hidden-xs-down">Blog</span></a> </li>
        <li class="nav-item"> <a class="nav-link" id="seo-nav-dynamic" data-toggle="tab" href="#seo" role="tab"
            @click="changeTab('SEO')"><span class="hidden-sm-up"></span> <span class="hidden-xs-down">SEO
              Setting</span></a> </li>
      </ul>
      <div class="tab-content">
        <div class="tab-pane fade show active" id="main" role="tabpanel">
          <div class="card">
            <div class="card-header">
              <h5 class="card-title mb-0">Hero Image</h5>
              <small>Last updated : {{(row_hero.ahi_update_date||"").dates("format")}}</small>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mb-3">
                  <div class="wrap_slider_img">
                    <div class="bullet-cta">
                      <a href="javascript:;" v-tooltip="'Edit Hero Image'" @click="openHero('Article',row_hero)"
                        class="bullet_edit finger"><i class="fas fa-pencil-alt"></i></a>
                    </div>
                    <img
                      :src="row_hero.ahi_background === '' ? baseUrl+'/img/no-image.jpg' : uploader((row_hero.ahi_background || '').removeWebp())"
                      class="img-responsive" />
                    <div class="slider_name">
                      <p># {{row_hero.ahi_title}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 mb-3">
                  <div class="wrap_slider_img">
                    <div class="bullet-cta">
                      <a href="javascript:;" v-tooltip="'Edit Hero Image'" @click="openHero('News',row_hero2)"
                        class="bullet_edit finger"><i class="fas fa-pencil-alt"></i></a>
                    </div>
                    <img
                      :src="row_hero2.ahi_background === '' ? baseUrl+'/img/no-image.jpg' : uploader((row_hero2.ahi_background || '').removeWebp())"
                      class="img-responsive" />
                    <div class="slider_name">
                      <p># {{row_hero2.ahi_title}}</p>
                    </div>
                  </div>
                </div>

              </div>

              <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
                <form v-if="isHero" v-on:submit.prevent="handleSubmit(doSubmitHero)">
                  <div class="row mt-4" id="collapseExample">
                    <div class="col-md-8 mb-3">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="panel_group">
                            <div class="panel_heading">Hero Image {{isHeroType}}</div>
                            <div class="panel_body">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="control-label">Title <span class="text-danger mr5">*</span></label>
                                    <input v-model="form_hero.ahi_title" type="text" id="firstName" class="form-control"
                                      placeholder="Page Title">
                                    <VValidate name="Title" v-model="form_hero.ahi_title" rules="required" />

                                  </div>
                                </div>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <label class="control-label">Description <span class="text-danger">*</span></label>
                                    <textarea class="form-control" v-model="form_hero.ahi_description" rows="2"
                                      placeholder="Page Description">

                                      </textarea>
                                    <VValidate name="Hero Description" v-model="form_hero.ahi_description"
                                      rules="required" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-3">
                      <Uploader v-model="form_hero.ahi_background" type="hero" label="Hero Image" />
                      <VValidate name="Hero Image" v-model="form_hero.ahi_background" rules="required" />
                      <div class="form-group mt-3">
                        <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                        <input type="text" v-model="form_hero.ahi_alt" class="form-control" placeholder="Alt Image">
                        <VValidate name="Alt Image Hero" v-model="form_hero.ahi_alt" rules="required" />
                      </div>

                      <button type="submit" class="btn btn-rounded btn-block btn-info mt-5">Submit</button>

                    </div>
                  </div>
                </form>
              </ValidationObserver>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="row">
                <div class="col-md-9">
                  <h5 class="card-title mb-0">Blog List</h5>
                </div>
              </div>
            </div>
            <div class="card-body">            
              <div class="row mb-3">
                <div class="col-md-8 col-12">
                
                  <div class="btn-group col-md-3 col-12 m-b-10" role="group" aria-label="Button group with nested dropdown">
                    <div class="input-group">
                      <select v-model="filter.type" class="form-control custom-select" @change="doFilter(), isFilter = true">
                        <option value="0" selected="selected">-- Filter Type --</option>
                        <option value="A">Articles</option>
                        <option value="N">News and Updates</option>
                      </select>
                    </div>
                  </div>

                  <div class="btn-group col-md-3 col-12 m-b-10" role="group" aria-label="Button group with nested dropdown">
                    <div class="input-group">
                      <select v-model="filter.category" class="form-control custom-select"
                        v-on:change="doFilter(), isFilter = true">
                        <option value="0" selected>-- Filter by Category -- </option>
                        <option v-for="(v, i) in category" :key="i" :value="v.aac_id">{{v.aac_name}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="btn-group col-md-3 col-12 m-b-10" role="group" aria-label="Button group with nested dropdown">
                    <div class="input-group">
                      <select v-model="filter.status" class="form-control custom-select"
                        v-on:change="doFilter(), isFilter = true">
                        <option value="0" selected>-- Filter Status -- </option>
                        <option value="P">Active</option>
                        <option value="N">Inactive </option>
                        <option value="D">Draft </option>
                      </select>
                    </div>
                  </div>

                  <div class="btn-group col-md-3 col-12 m-b-10" role="group" aria-label="Button group with nested dropdown">
                    <div class="input-group">
                      <select v-model="filter.sort" class="form-control custom-select" @change="doFilter(), isFilter = true">
                        <option value="0" selected="selected">-- Sort By --</option>
                        <option value="most">Most Viewed</option>
                        <option value="least">Least Viewed</option>
                        <option value="oldest">Oldest Publish</option>
                        <option value="newest">Newest Publish</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <form @submit.prevent="doFilter(),doSearch()">
                    <div class="form-group mb-0">
                      <div class="input-group px-2">
                        <input v-model="filter.search" type="text" class="form-control"
                          placeholder="Type and Press Enter" @input="checkDisabled" />
                        <div class="input-group-append">
                          <button disabled="disabled" id="btn-search" class="btn btn-info" type="submit"><i
                              class="fas fa-search"></i></button>
                        </div>
                        <button v-if="isSearch" v-tooltip="'Clear Search Results'" @click="doReset(),clsSearch()"
                          type="button" class="btn btn-danger ml-1"><i class="fa fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="row" v-if="data.data===false">
                <div class="col-md-12 d-flex justify-content-center">
                  <Loader class="mt10 mb10" />
                </div>
              </div>
              <div class="row gutter-20" v-if="data.data.length > 0">
                <div v-for="(v, i) in data.data" :key="i"
                  :class="`col-md-3 ${v.aa_is_featured == 'Y' ? 'order-0' : 'order-1'}`">
                  <div class="wrap_article" v-tooltip="v.aa_update_date ? 'Last updated : ' + v.aa_update_date.dates('format2') : 'Created : ' + v.aa_create_date.dates('format2')">
                    <div class="wrap_thumb">
                      <img class="img-responsive" :src="uploader((v.aa_img || '').removeWebp())"/>
                      <div class="bullet-cta-left">
                        <span v-if="v.aa_type == 'A'" class="label label-primary mr-1">Articles</span>
                        <span v-if="v.aa_type == 'N'" class="label label-warning mr-1">News and Updates</span>
                      </div>
                      <div class="bullet-cta">
                        <router-link v-tooltip="'Edit'" :to="{name:$route.name, params:{pageSlug:v[idKey]}}"
                          class="bullet_edit mr-1">
                          <i class="fas fa-pencil-alt"></i>
                        </router-link>
                        <a v-tooltip="'Delete'" @click.prevent="doDelete(v.aa_id, v)" class="bullet_delete"
                          style="cursor: pointer;"><i class="far fa-trash-alt"></i></a>
                      </div>
                    </div>
                    <div class="wrap_content_post text-center">
                      <div>
                        <span v-if="v.aa_is_featured == 'Y'" class="label label-info mr-1">Featured</span>
                        <span v-if="v.aa_is_active == 'D'" class="label label-warning">Draft</span>
                        <span v-if="v.aa_is_active == 'P'" class="label label-success">Active</span>
                        <span v-if="v.aa_is_active == 'N'" class="label label-danger">Inactive</span>
                      </div>

                      <h6 v-for="(vc,kc) in v.category" :key="kc" style="display:contents;">{{vc.aac_name}}
                        {{v.category.length == (kc+1) ? '' : ' | '}}</h6>
   
                      <h2 v-tooltip="v.aa_title.limitChar(50).endsWith('...') ? v.aa_title : ''">{{ v.aa_title.limitChar(50)}}</h2>
                      <ul class="text-center d-flex flex-column">
                        <li :class="v.aa_publish_date ? 'pb-0' : ''">{{(v.aa_publish_date || '').dates('format4')}}</li>
                        <li>{{v.aa_author}}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="data.data.length <= 0">              
                <div class="col-md-12 mb-3 d-flex justify-content-center">
                  <div v-if="isFilter || isSearch">
                    <template v-if="filter.type">
                      <h4>{{filter.type == 'A' ? 'Article Not Found' : 'News and Updates Not Found'}}</h4>
                    </template>
                    <template v-else>
                      <h4>Blog Not Found</h4>
                    </template>
                  </div>
                  <div v-else>
                    <h4>Blog Not Available</h4>                  
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer bg_gray" v-if="data.data!==false&&data.data.length">
              <div class="row">
                <div class="col-md-12">
                  <div class="pagination-flat float-right">
                    <Pagination :data="data" :limit="4" @pagination-change-page="doPaginate">
                      <span slot="prev-nav"><i class="icon-arrow-left"></i></span>
                      <span slot="next-nav"><i class="icon-arrow-right"></i></span>
                    </Pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="seo" role="tabpanel">
          <div class="card" v-if="isMeta">
            <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSeo">
              <form v-on:submit.prevent="handleSubmit(doSubmitSeo)">
                <!-- FOR ARTICLE -->
                <div class="card-body pb-0">
                    <h5>Edit SEO Article</h5>
                </div>
                <div class="card-body pb-0">
                  <div class="form-group row align-items-center mb-0">
                    <label for="" class="col-form-label col-auto">Auto-fill SEO?</label>
                    <div class="col-md">
                      <div class="ia-switch" @click.stop="editAutoFill">
                        <input type="checkbox" v-model="isCheck" name="toggleAutoSeo" id="toggleAutoSeo"
                          class="ia-switch-input">
                        <label for="toggleAutoSeo" class="ia-input-label"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Title <a
                            v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <input v-model="seoStatic.sss_title" @input="checkDisabledSeo" type="text" id="firstName" class="form-control"
                          placeholder="Meta Title">
                        <VValidate name="Meta Title" v-model="seoStatic.sss_title" :rules="{required: 1}" />
                      </div>

                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="control-label">
                          Meta Keyword
                          <a class="help_info"
                            v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                            ?
                          </a>
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input-tag v-model="seoStatic.sss_keyword" @input="checkDisabledSeo"></input-tag>

                        <VValidate name="Keyword" v-model="seoStatic.sss_keyword" rules="required" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Description <a
                            v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <textarea v-model="seoStatic.sss_desc" class="form-control" rows="4" @input="checkDisabledSeo"></textarea>

                        <VValidate name="Meta Description" v-model="seoStatic.sss_desc"
                          :rules="{required: 1}" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="form-actions text-right">
                    <button @click="reset" type="button" class="btn  btn-rounded  btn-secondary" id="button-cancel"
                      disabled>Cancel
                    </button>
                    <button type="submit" class="btn  btn-rounded  btn-info">Submit</button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
            <ValidationObserver v-slot="{ handleSubmit }" ref="VFormSeo2">
              <form v-on:submit.prevent="handleSubmit(doSubmitSeo2)">
                <!-- FOR NEWS AND UPDATE-->
                <div class="card-body pb-0">
                    <h5>Edit SEO News and Update</h5>
                </div>
                <div class="card-body pb-0">
                  <div class="form-group row align-items-center mb-0">
                    <label for="" class="col-form-label col-auto">Auto-fill SEO?</label>
                    <div class="col-md">
                      <div class="ia-switch" @click.stop="editAutoFill2">
                        <input type="checkbox" v-model="isCheck2" name="toggleAutoSeo2" id="toggleAutoSeo2"
                          class="ia-switch-input">
                        <label for="toggleAutoSeo2" class="ia-input-label"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Title <a
                            v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <input v-model="seoStatic2.sss_title" @input="checkDisabledSeo2" type="text" id="firstName" class="form-control"
                          placeholder="Meta Title">
                        <VValidate name="Meta Title" v-model="seoStatic2.sss_title" :rules="{required: 1}" />
                      </div>

                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label for="" class="control-label">
                          Meta Keyword
                          <a class="help_info"
                            v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                            ?
                          </a>
                          <span class="text-danger mr5">*</span>
                        </label>
                        <input-tag v-model="seoStatic2.sss_keyword" @input="checkDisabledSeo2"></input-tag>

                        <VValidate name="Keyword" v-model="seoStatic2.sss_keyword" rules="required" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="control-label">Meta Description <a
                            v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                            class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                        <textarea v-model="seoStatic2.sss_desc" @input="checkDisabledSeo2" class="form-control" rows="4"></textarea>

                        <VValidate name="Meta Description" v-model="seoStatic2.sss_desc"
                          :rules="{required: 1}" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="form-actions text-right">
                    <button @click="reset2" type="button" class="btn  btn-rounded  btn-secondary" id="button-cancel2"
                      disabled>Cancel
                    </button>
                    <button type="submit" class="btn  btn-rounded  btn-info">Submit</button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
          <div class="card" v-else>
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h5>SEO Article</h5>
                </div>
                <div class="col-md-6 text-right">
                  <label class="text-primary">Last updated : {{(seoStatic.sss_update_date||"").dates('format2')}}</label>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Title <a
                        v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                    <p>{{seoStatic.sss_title}}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="" class="control-label">
                      Meta Keyword
                      <a class="help_info"
                        v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                        ?
                      </a>
                      <span class="text-danger mr5">*</span>
                    </label>
                    <div></div>
                    <template v-for="(v,k) in seoStatic.sss_keyword">
                      <label class="label label-success mr-1" :key="k">
                        {{v}}
                      </label>
                    </template>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Description <a
                        v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                    <p>{{seoStatic.sss_desc}}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h5>SEO News and Update</h5>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Title <a
                        v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                    <p>{{seoStatic2.sss_title}}</p>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="" class="control-label">
                      Meta Keyword
                      <a class="help_info"
                        v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                        ?
                      </a>
                      <span class="text-danger mr5">*</span>
                    </label>
                    <div></div>
                    <template v-for="(v,k) in seoStatic2.sss_keyword">
                      <label class="label label-success mr-1" :key="k">
                        {{v}}
                      </label>
                    </template>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label class="control-label">Meta Description <a
                        v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                        class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                    <p>{{seoStatic2.sss_desc}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="form-actions text-right">
                <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="row" v-else>
      <div class="col-lg-12">
        <ValidationObserver v-slot="{ handleSubmit }" ref="VForm">
          <form v-on:submit.prevent="handleSubmit(doPost,clsMeta)">
            <div>
              <div class="card" style="overflow-y: auto; overflow-x:hidden;">
                <div class="row">
                  <div class="col-md-12">
                    <ul class="nav nav-tabs customtab" role="tablist">
                      <li class="nav-item"> <a class="nav-link active" @click="changeTab('mainForm')" id="main-nav"
                          data-toggle="tab" href="#editor" role="tab"><span class="hidden-sm-up"></span> <span
                            class="hidden-xs-down">Article</span></a> </li>
                      <li class="nav-item"> <a class="nav-link" @click="changeTab('SEOForm')" id="seo-nav"
                          data-toggle="tab" href="#seo" role="tab"><span class="hidden-sm-up"></span> <span
                            class="hidden-xs-down">SEO Setting</span></a> </li>
                    </ul>
                    <div class="tab-content">
                      <div class="tab-pane active" id="editor" role="tabpanel">
                        <div class="card-body">
                          <div class="form-body">
                            <div class="row" @click="suggestTag($event)">
                              <div class="col-md-8 mt-3">
                                <div class="row">
                                  <div class="col-md-11">
                                    <div class="form-group">
                                      <label class="control-label">Title<span class="text-danger mr5">*</span></label>
                                      <input v-model="row.aa_title" type="text" class="form-control"
                                        placeholder="Title">
                                      <VValidate name="Title" v-model="row.aa_title" :rules="mrValidation.aa_title" />
                                    </div>
                                  </div>
                                  <div class="col-md-7">
                                    <div class="form-group">
                                      <label class="control-label"> Type <span
                                          class="text-danger mr5">*</span></label>
                                        <vSelect v-model="row.aa_type" :options="type" :reduce="v => v.ty_id"
                                          placeholder="Select One" label="ty_name"></vSelect>
                                        <VValidate name="Type" v-model="row.aa_type" rules="required"/>
                                    </div>
                                  </div>
                                  <div class="col-md-8">
                                    <div class="form-group">
                                      <label class="control-label"> Category <span
                                          class="text-danger mr5">*</span></label>
                                      <div class="input-group">
                                        <vSelect 
                                          v-model="row.aa_category" 
                                          style="width: 100%;" 
                                          class="vs__multiple"
                                          multiple 
                                          :reduce="cat => cat.aac_id" 
                                          :options="rowcat"
                                          placeholder="-- Select One --" 
                                          label="aac_name"
                                          :value="cat=>cat.aac_id == row.aa_category">
                                        </vSelect>
                                        <div class="input-group-append" style="position: absolute;right: 0;">
                                          <button @click="setModal()" class="btn btn-secondary" type="button"><i
                                              class="fas fa-plus"></i></button>
                                        </div>
                                      </div>

                                      <VValidate name="Category" v-model="row.aa_category"
                                        :rules="mrValidation.aa_category" />
                                    </div>
                                  </div>
                                  <!--
																	<div class="col-md-11">
																		<div class="form-group">
																			<label class="control-label">Slug<span class="text-danger mr5">*</span></label>

																			<div class="input-group">
																				<input v-model="row.aa_slug" type="text" id="slug" class="form-control"
																					placeholder="Slug" :disabled="isSlug">
                                          <div class="input-group-append">
                                            <button type="button" class="btn btn-secondary" @click="doSlug()"><i class="icon-pencil"></i></button>
                                          </div>
																			</div>
																			<VValidate name="Slug" v-model="row.aa_slug"
																				:rules="{required:1, regex:/^[^\s]+$/ }" />
																		</div>
																	</div>
                                  -->

                                  <div class="col-md-11">
                                    <div class="form-group">
                                      <label class="control-label">Detail Description <span
                                          class="text-danger mr5">*</span></label>
                                      <CKEditor :value.sync="row.aa_desc" />
                                      <VValidate name="Description" v-model="row.aa_desc"
                                        :rules="mrValidation.aa_desc" />
                                    </div>
                                  </div>

                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <!--
                                      <span v-for="(t, p) in mrTags" :key="p">
                                        {{t.aat_tag}} <span>||</span>
                                      </span>
                                      -->
                                      <label class="control-label">Tags <span class="text-danger mr5">*</span></label>
                                      
                                      <div>
                                      <input-tag v-on:input="inptag" v-model="row.aat_tag" placeholder="Type and click enter to add it"
                                        @focusin.native="isSuggestTag = true" 
                                        ref="input_tags"></input-tag>
                                      </div>
                                      
                                      <div class="mt-3 wrapper-suggest-tag" v-if="isSuggestTag && mrTags.length > 0"
                                        style="z-index: 99;">
                                        <sup style="padding: 1em;">Suggestion Tags : </sup>
                                        <p v-for="(v, k) in mrTags" :key="k"
                                          @click.prevent="addSuggestTag(v.aat_tag, v)" class="tag-version">
                                          {{ v.aat_tag }}</p>
                                      </div>

                                      <VValidate name="Tag" v-model="row.aat_tag" rules="required" />

                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4">
                                <label class="control-label">Main Image <span class="text-danger mr5">*</span></label>
                                <Uploader v-model="row.aa_img" type="article" label="Photo" />
                                <VValidate name="Image" v-model="row.aa_img" :rules="mrValidation.aa_img" />

                                <div class="form-group mt-3">
                                  <label class="control-label">Alt Image <span class="text-danger">*</span></label>
                                  <input type="text" v-model="row.aa_alt" class="form-control" placeholder="Alt Image">
                                  <VValidate name="Alt Image" v-model="row.aa_alt" rules="required" />
                                </div>

                                <div class="form-group mt-3">
                                  <label class="control-label">Document</label>
                                  <Uploader type="document" v-model="row.aa_file" label="Document" uploadType="upload"
                                    fileName="file" />
                                </div>

                                <div class="row mt-3">
                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Author <span class="text-danger mr5">*</span></label>
                                      <input v-model="row.aa_author" type="text" id="firstName" class="form-control"
                                        placeholder="Author">
                                      <VValidate name="Author" v-model="row.aa_author"
                                        :rules="mrValidation.aa_author" />
                                    </div>
                                  </div>

                                  <div class="col-md-12">
                                    <div class="form-group">
                                      <label class="control-label">Publish Date <span
                                          class="text-danger mr5">*</span></label>
                                      <datepicker input-class="inp-date col-md-12" placeholder="Select Date"
                                        class="my-datepicker" calendar-class="my-datepicker_calendar"
                                        v-model="row.aa_publish_date">
                                      </datepicker>
                                      <span class="ic_apeend"><i class="icon-calender"></i></span>
                                      <VValidate name="Publish Date" v-model="row.aa_publish_date" rules="required" />
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <label class="control-label">Featured Article</label>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                                          <input type="checkbox" v-model="featured" @click="doCheck()"
                                            class="custom-control-input" :checked="featured" id="chkPassport" value="check">
                                          <label class="custom-control-label" for="chkPassport" >Set As Featured
                                            Article</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12" v-if="!isAutoPost">
                                    <label class="control-label">Auto Post</label>
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="custom-control custom-checkbox mr-sm-2 mb-3">
                                          <input type="checkbox" v-model="autoPost" @click="doAuto()"
                                            class="custom-control-input" :checked="autoPost" id="chkAutoPost" value="check">
                                          <label class="custom-control-label" for="chkAutoPost" >Set As Auto
                                            Post</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-12">
                                    <label class="control-label">Status <span class="text-danger mr5">*</span> </label>
                                    <div class="row">
                                      <div class="col-md-3">
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="customRadio1" v-model="row.aa_is_active" value="P"
                                            name="radioRow" class="custom-control-input">
                                          <label class="custom-control-label" for="customRadio1">Active</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="customRadio2" v-model="row.aa_is_active" value="D"
                                            name="radioRow" class="custom-control-input">
                                          <label class="custom-control-label" for="customRadio2">Draft</label>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="custom-control custom-radio">
                                          <input type="radio" id="customRadio3" v-model="row.aa_is_active" value="N"
                                            name="radioRow" class="custom-control-input">
                                          <label class="custom-control-label" for="customRadio3">Inactive</label>
                                        </div>
                                      </div>
                                      <VValidate name="Status" v-model="row.aa_is_active"
                                        :rules="mrValidation.aa_is_active" />
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                      <div class="tab-pane" id="seo" role="tabpanel">

                        <div class="card-body" v-if="isMeta">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="control-label">Meta Title <a
                                    v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                    class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                <input v-model="row.aa_meta_title" type="text" id="firstName" class="form-control"
                                  placeholder="Meta Title">
                                <VValidate name="Meta Title" v-model="row.aa_meta_title"
                                  :rules="{required: mrValidation.aa_meta_title}" />
                              </div>

                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="" class="control-label">
                                  Meta Keyword
                                  <a class="help_info"
                                    v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                    ?
                                  </a>
                                  <span class="text-danger mr5">*</span>
                                </label>
                                <input-tag v-model="row.aa_meta_keyword"></input-tag>
                                <VValidate name="Keyword" v-model="row.aa_meta_keyword"
                                  :rules="mrValidation.aa_meta_keyword" />
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group">
                                <label class="control-label">Meta Description <a
                                    v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                    class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                <textarea v-model="row.aa_meta_description" class="form-control" rows="4"></textarea>

                                <VValidate name="Meta Description" v-model="row.aa_meta_description"
                                  :rules="{required: mrValidation.aa_meta_description}" />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="card" v-else>
                          <div class="card-body">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Title <a
                                      v-tooltip="'This field functions to ease Google to understand the title of this website page. Tips: Make sure the characters length is less than 70 chars.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <p>{{row.aa_meta_title}}</p>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label for="" class="control-label">
                                    Meta Keyword
                                    <a class="help_info"
                                      v-tooltip="'It can be used to give more information to search engines on what a page is about. The keywords are generally written in lowercase and separated with a comma.'">
                                      ?
                                    </a>
                                    <span class="text-danger mr5">*</span>
                                  </label>
                                  <div></div>
                                  <template v-for="(v,k) in row.aa_meta_keyword">
                                    <label class="label label-success mr-1" :key="k">
                                      {{v}}
                                    </label>
                                  </template>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label class="control-label">Meta Description <a
                                      v-tooltip="'A meta description is an element that contains a short summary of the page and generates the brief snippet we see underneath a site`s title in Google`s search results.'"
                                      class="help_info">?</a> <span class="text-danger mr5">*</span></label>
                                  <p>{{row.aa_meta_description}}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div class="form-actions text-right" v-if="tab === 'main' || tab === 'mainForm'">
                    <button type="button" @click="cancelButton()"
                      class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                    <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                  </div>
                  <div class="form-actions text-right" v-else>
                    <button type="button" @click="cancelButton()"
                      class="btn mr-2 btn-rounded  btn-secondary">Cancel</button>
                    <template v-if="isMeta">
                      <button type="submit" @click="doValidate()" class="btn  btn-rounded  btn-info">Submit</button>
                    </template>
                    <template v-else>
                      <a href="javascript:;" @click="editMeta()" class="btn btn-rounded btn-info">Edit</a>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!--  pindah sini -->
    <VModalVue :open.sync="isModalOpen" :inlineBackdrop="false" :title="'Article Category'">
      <template v-slot:title>
        <h4>Article Category</h4>
      </template>

      <template v-slot:body>
        <ValidationObserver v-slot="{ handleSubmit }" ref="VFormCat">
          <form v-on:submit.prevent="handleSubmit(doSubmitCategory)">
            <div class="modal-body">
              <div class="row">
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label class="control-label">Category Name <span class="text-danger mr5">*</span> </label>
                    <input type="text" v-model="rowcatadd.name" id="firstName" class="form-control"
                      placeholder="Category Name">
                    <VValidate name="Category Name" v-model="rowcatadd.name" :rules="{required : 1, min : 3}" />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="submit" class="btn btn-rounded  btn-info">Submit</button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </VModalVue>

  </div>
</template>

<style scoped>
  .dropdown-toggle .clear {
    display: none;
  }

  .wrapper-suggest-tag {
    height: 200px;
    overflow: auto;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, .07);
    padding-top: 1em;
  }

  .wrapper-suggest-tag p {
    padding: .5em 1em .5em 1em;
  }

  .wrapper-suggest-tag p:hover {
    background: #03a9f3;
    color: #fff;
    cursor: pointer;
  }

  button:disabled {
    cursor: auto;
  }
</style>

<script>
  import GlobalVue from '@libs/Global'
  import VModalVue from "../components/VModal.vue"
  import Datepicker from 'vuejs-datepicker'
  import CKEditor from '@/components/CKEditor'
  // import Instafeed from 'instafeed'

  import Gen from '@libs/Gen.js'

  let $ = global.jQuery
  let _ = global._

  export default {
    extends: GlobalVue,
    components: {
      VModalVue,
      Datepicker,
      CKEditor
    },
    data() {
      return {
        order: {},
        idKey: 'aa_id',
        statusKey: 'aa_is_active',
        isModalOpen: false,
        isSlug: true,
        isSearch: false,
        isHero: false,
        row: {},
        rowcat: [],
        category: [],
        featured: false,
        autoPost: false,
        isAutoPost: false,
        rowcatadd: {
          name: "",
          active: "Y",
        },
        row_hero: {},
        row_hero2: {},
        DatePickerFormat: 'dd/MM/yyyy',
        seoStatic: {},
        seoStatic2: {},
        metaLatest: {},
        metaLatest2: {},
        mrTags: [],
        tagsTemp: [],
        isSuggestTag: false,
        isFilter: false,
        isMeta: false,
        isCheck: false,
        isCheck2: false,
        TabSeo: JSON.parse(localStorage.getItem("SEO")),
        tab: "main",
        isSliderOp : [],
        isHeroType: 'Article',
        form_hero : {},
        type:[
          {ty_id : 'A', ty_name : 'Article'},
          {ty_id : 'N', ty_name : 'News and Update'},
        ]
      }
    },
    mounted() {
      this.apiGet()
      this.apiGetHeroImage();
      this.dosetStatus();
      this.filter["status"] = 0;
      this.filter["category"] = 0;
      this.filter["sort"] = 0;
      this.filter["type"] = 0;
      this.isSuggestTag = false

      
      if(this.$route.params.pageSlug){   
        if(this.isAdd == false){
          this.checkTags()
          setTimeout(()=>{
            this.featured = this.row["aa_is_featured"] == 'Y' ? true : false;
            this.autoPost = this.row["aa_is_auto_post"] == 'Y' ? true : false;
            this.isAutoPost = this.row["aa_is_auto_post"] == 'Y' ? true : false;
          },1000)
        }
      }
      else{
        this.featured = false
        this.autoPost = false
        this.isAutoPost = false
      }

      if (this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.val === "Y") {
        this.tab = 'SEO'

        $("#main").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav-dynamic").removeClass("active");
        $("#seo-nav-dynamic").addClass("active");

      }

      if (this.TabSeo !== null && this.TabSeo.menu === this.$route.name && this.TabSeo.valChild === "Y") {
        this.tab = 'SEOForm'

        $("#editor").removeClass("active").removeClass("show");
        $("#seo").addClass("active").addClass("show");

        $("#main-nav").removeClass("active");
        $("#seo-nav").addClass("active");

      }

      if (this.isList) {
        if (this.$route.query.category !== 0 || this.$route.query.status !== 0 || this.$route.query.page) {
          this.apiGet();
        }
        if(this.$route.query.search){
          if (this.filter.search.length <= 0) {
            this.isSearch = false
            $('#btn-search').attr("disabled", "disabled")
          } else {
            this.isSearch = true
            $('#btn-search').removeAttr("disabled")
          }
        }
        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      }

      if (this.isAdd) {
        this.isMeta = true
        setTimeout(() => {
          this.row = {}
          this.row.aa_is_active = "P";
        }, 500)
      }

    },
    methods: {
      splitTitle(content, len) {
        const countTitle = content.split(' ')
        const ctLen = countTitle.length
        if (ctLen > len) countTitle.splice(len)
        return `${countTitle.join(' ')} ${content.split(' ').length > len ? '...' : ''}`
      },
      checkDisabledSeo() {
        $('#button-cancel').removeAttr('disabled')
      },
      checkDisabledSeo2() {
        $('#button-cancel2').removeAttr('disabled')
      },
      clsMeta() {
        this.isMeta = true
      },
      reset() {
        this.apiGet()
        this.apiGetHeroImage()
        this.isCheck = false
        $('#button-cancel').attr('disabled', 'disabled')
      },
      reset2() {
        this.apiGet()
        this.apiGetHeroImage()
        this.isCheck2 = false
        $('#button-cancel2').attr('disabled', 'disabled')
      },
      cancelButton() {
        global.Swal.fire({
          icon: 'warning',
          title: 'Are You Sure to Cancel?',
          text: 'You will not able to recover this data.',
          showCancelButton: true,
          confirmButtonText: `Yes, Cancel`,
          cancelButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-custom',
            cancelButton: 'btn btn-outline-custom ml-1',
          },
          buttonsStyling: false,
        }).then(res => {
          if (res.value) {
            let confSeo = JSON.stringify({
              menu: this.$route.name,
              val: "N"
            })
            localStorage.setItem("SEO", confSeo)
            this.$router.push({
              name: this.$route.name
            })
          }
        })
      },
      editAutoFill() {
        if (!this.isCheck) {
          global.Swal.fire({
            icon: 'warning',
            title: 'Change back SEO Content to Auto-fill Mode?',
            text: 'Any changes you made will be lost!',
            showCancelButton: true,
            confirmButtonText: `Yes, change it!`,
            cancelButtonText: 'No Cancel',
            customClass: {
              confirmButton: 'btn btn-custom',
              cancelButton: 'btn btn-outline-custom ml-1',
            },
            buttonsStyling: false,
          }).then(res => {
            if (res.value) {
              this.isCheck = true
              this.seoStatic = {
                sss_id: this.seoStatic.sss_id,
                sss_title: this.metaLatest.aa_meta_title,
                sss_desc: this.metaLatest.aa_meta_description,
                sss_keyword: this.metaLatest.aa_meta_keyword
              }
              $('#button-cancel').removeAttr('disabled')
            } else {
              this.isCheck = false
            }
          })
        } else {
          this.apiGet()
        }
      },
      editAutoFill2() {
        if (!this.isCheck2) {
          global.Swal.fire({
            icon: 'warning',
            title: 'Change back SEO Content to Auto-fill Mode?',
            text: 'Any changes you made will be lost!',
            showCancelButton: true,
            confirmButtonText: `Yes, change it!`,
            cancelButtonText: 'No Cancel',
            customClass: {
              confirmButton: 'btn btn-custom',
              cancelButton: 'btn btn-outline-custom ml-1',
            },
            buttonsStyling: false,
          }).then(res => {
            if (res.value) {
              this.isCheck2 = true
              this.seoStatic2 = {
                sss_id: this.seoStatic2.sss_id,
                sss_title: this.metaLatest2.aa_meta_title,
                sss_desc: this.metaLatest2.aa_meta_description,
                sss_keyword: this.metaLatest2.aa_meta_keyword
              }
              $('#button-cancel2').removeAttr('disabled')
            } else {
              this.isCheck2 = false
            }
          })
        } else {
          this.apiGet()
        }
      },

      changeTab(tab) {
        this.tab = tab

        if (this.tab === "SEO") {
          let confSeo = JSON.stringify({
            menu: `${this.$route.name}`,
            val: "Y"
          })
          localStorage.setItem("SEO", confSeo)
        } else if (this.tab === "SEOForm") {
          let confSeo = JSON.stringify({
            menu: `${this.$route.name}`,
            val: "N",
            valChild: "Y"
          })
          localStorage.setItem("SEO", confSeo)
        } else {
          let confSeo = JSON.stringify({
            menu: `${this.$route.name}`,
            val: "N"
          })
          localStorage.setItem("SEO", confSeo)
        }

      },
      editMeta() {
        this.isMeta = true
        this.isCheck = false
        this.isCheck2 = false
      },
      apiGetCat(v) {
        Gen.apiRest(
          "/get/" + "ArticleCat"
        ).
        then(res => {
            this.rowcat = res.data.rowcat;
            if (v) {
              if (!this.isAdd) {
                let newCat = this.rowcat[this.rowcat.length - 1]["aac_id"]
                this.row.aa_category.push(newCat)
              } else {
                if (this.row.aa_category) {
                  let newCat = this.rowcat[this.rowcat.length - 1]
                  this.row.aa_category.push(newCat.aac_id)
                }
              }
            }
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },

      doBackSuccessMeta(){
        this.tab = "main"
        $("#seo").removeClass("active").removeClass("show");
        $("#main").addClass("active").addClass("show");

        $("#seo-nav-dynamic").removeClass("active");
        $("#main-nav-dynamic").addClass("active");

        let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
        localStorage.setItem("SEO", confSeo)
      },

      doSubmitSeo(IdFormSeo = 'VFormSeo') {
        this.seoStatic.sss_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + "StaticSEO",
          _.assign({
            type: 'update',
            id: this.seoStatic.sss_id
          }, this.seoStatic),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.seoStatic = resp.data
              this.doBackSuccessMeta()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSeo
        )
      },

      doSubmitSeo2(IdFormSeo2 = 'VFormSeo2') {
        this.seoStatic2.sss_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + "StaticSEO",
          _.assign({
            type: 'update',
            id: this.seoStatic2.sss_id
          }, this.seoStatic2),
          (type, resp) => {
            if (type == 'success') {
              this.isMeta = false
              this.seoStatic2 = resp.data
              this.doBackSuccessMeta()
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IdFormSeo2
        )
      },
      // 
      doValidate() {
        if(!this.row.aa_category || !this.row.aa_title || !this.row.aat_tag || !this.row.aa_img || !this.row
          .aa_author || !this.row.aa_publish_date)
        {
         $("#editor").addClass("active");
          $("#seo").removeClass("active");

          $("#main-nav").addClass("active");
          $("#seo-nav").removeClass("active");

        } else if ((this.row.aa_meta_title && this.row.aa_meta_title.length < 1) || (this.row.aa_meta_description && this.row.aa_meta_description.length < 1) || !this.row.aa_meta_title || !this.row.aa_meta_description || !this.row.aa_meta_keyword) {
           $("#editor").removeClass("active");
          $("#seo").addClass("active");

          $("#main-nav").removeClass("active");
          $("#seo-nav").addClass("active");
          return;
        }
      },
      doCheck() {
        this.featured = !this.featured;
      },
      doAuto() {
        this.autoPost = !this.autoPost;
      },
      openHero(n,data) {
        this.isSliderOp.push(n)
        if(n == this.isSliderOp[1]){     
          this.isHero = !this.isHero
        }
        else if(n !== this.isSliderOp[1]){
          this.isHero = true
        }
        else{
          this.isHero = true
        }

        if(this.isSliderOp.length > 2){
          this.isSliderOp.shift()
        }

        this.isHeroType = n
        this.form_hero = data
      },
      doSearch() {
        this.isSearch = true
      },
      clsSearch() {
        this.isSearch = false
        this.filter["status"] = 0;
        this.filter["category"] = 0;

        setTimeout(() => {
          $('#btn-search').attr("disabled", "disabled")
        }, 0)
      },
      apiGetHeroImage: function () {
        if (this.pageSlug) this.loadingOverlay = true
        this.data.data = false
        Gen.apiRest(
          "/get/" + "HeroImageBlog"
        ).
        then(res => {
            this.loadingOverlay = false
            this.row_hero = res.data.row_hero;
            this.row_hero2 = res.data.row_hero2;          
          })
          .catch(() => {
            this.loadingOverlay = false
          })
      },
      doSubmitHero() {
        this.form_hero.ahi_update_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'updateHeroImage',
            id: this.form_hero.ahi_id
          }, this.form_hero),
          (type, resp) => {
            if (type == 'success') {
              this.form_hero = resp.data
              this.apiGet();
              this.apiGetHeroImage();
              this.$router.push({
                name: this.$route.name
              })
              
              window.scrollTo(0, 0)
              this.isHero = false
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
        )
      },
      doSlug: function () {
        this.isSlug = !this.isSlug
      },
      setModal() {
        this.isModalOpen = true
      },
      changeSlug() {
        this.row.aa_slug = this.row.aa_slug.replace(/\s+/g, '-').toLowerCase();
      },
      slugChange() {
        this.row.aa_slug = this.row.aa_title;
        this.row.aa_slug = this.cleanMultipleDash(this.row.aa_title.toLowerCase().trim().replace(/ /g, '-').replace(
          /[^\w-]+/g, ''));
      },
      cleanMultipleDash(string) {
        string = string.replace(/-{2,}/g, '-');
        return string;
      },
      doSubmitArt() {
        this.isSlug = true
      },
      doPost() {
        this.row["aa_is_featured"] = this.featured ? 'Y' : 'N';
        this.row["aa_is_auto_post"] = this.autoPost ? 'Y' : 'N';        
        if (this.isAdd) {
          this.row["aa_create_by"] = this.user.id
          this.doSubmitCRUD();
          // var feed = new Instafeed({
          //   accessToken: 'IGQVJXM2pKTGZAxaGUxRVUwMEFGN184QjBGT1RMTHozZAEh5cTFBUUk2dTU4cFRiUUUxMERxZAjg0NmtGXzY4bmQ2R0d5NVp0VjJzVjVRa3VZANHM1aG9OUnZAfcWY5bzhFRXV6WVBPbFU2NWs4UlpIdG5IdwZDZD',
          //   template:'<a href="#"><img title="hello" src="hello.jpg" /></a>',
          //   clientId : '2940982772782071'
          // });
          // feed.run();

        } else {
          this.row["aa_update_by"] = this.user.id
          this.doSubmitCRUD();
        }
      },
      doSubmitCategory: function (IDFORMCAT = 'VFormCat') {
        this.rowcatadd.active = "Y";
        this.rowcatadd.create_by = this.user.id;
        return this.doSubmit(
          "/do/" + this.modulePage,
          _.assign({
            type: 'AddArticleCat'
          }, this.rowcatadd),
          (type, resp) => {
            if (type == 'success') {
              this.apiGetCat(true);
              this.isModalOpen = false;
              this.rowcatadd.name = "";


              global.Swal.fire({
                title: 'Category added Successfully!',
                icon: 'success',
              })
            } else {
              if (resp.response.status == 422) {
                if ((_.values(resp.response.data)[0][0] || "").indexOf('validation') > -1) {
                  var msg = ""
                  $.each(Object.keys(resp.response.data), (i, v) => {
                    msg = msg + " <br/>" + v + " : " + _.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                } else {
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              } else if (resp.response.status == 400) {
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDFORMCAT, true
        )
      },
      dosetStatus() {
        setTimeout(() => {
          this.row[this.statusKey] = "P"
        }, 300)
      },
      
      checkTags(){     
        setTimeout(()=>{
          for(let i = 0; i < this.mrTags.length; i++){
            for(let j = 0; j < this.row.aat_tag.length; j++){            
              if(this.mrTags[i].aat_tag == this.row.aat_tag[j]){
                this.mrTags.splice(i, 1)
              }
            }
          }
        },1500)

      },
      inptag(e){
        let val = e[e.length -1];
        this.mrTags = this.mrTags.filter(tag => tag.aat_tag !== val)
        this.isSuggestTag = false   

      },
      addSuggestTag(text) {
        this.row.aat_tag = this.row.aat_tag === undefined ? [text] : [...this.row.aat_tag, text]
        this.$refs.input_tags.value = this.row.aat_tag
        setTimeout(()=>{
          this.mrTags = this.mrTags.filter(tag => tag.aat_tag !== text)
          this.isSuggestTag = false
        },0)
      },

      suggestTag(e) {
        let classComponent = e.target.classList
        if (classComponent.contains('new-tag') || classComponent.contains('tag-version') || classComponent.contains(
            'vue-input-tag-wrapper')) {
          this.isSuggestTag = true
        } else {
          this.isSuggestTag = false
        }
      },
      checkDisabled() {
        if (this.filter.search.length <= 0) {
          $('#btn-search').attr("disabled", "disabled")
        } else {
          $('#btn-search').removeAttr("disabled")
        }
      }
    },
    watch: {
      $route() {
        this.rowcat = []
        this.apiGet()
        this.isSuggestTag = false
        this.apiGetHeroImage()

        if(this.$route.params.pageSlug){
          if(this.isAdd == false){
            setTimeout(()=>{
              this.checkTags()
              this.featured = this.row["aa_is_featured"] == 'Y' ? true : false;
              this.autoPost = this.row["aa_is_auto_post"] == 'Y' ? true : false;
              this.isAutoPost = this.row["aa_is_auto_post"] == 'Y' ? true : false;
            },1000)
          }
        }
        else{
          this.featured = false
          this.autoPost = false
          this.isAutoPost = false
        }

        this.tab = 'main'

        if (this.isAdd) {
          this.isMeta = true
        }
        if (this.isList) {
          let confSeo = JSON.stringify({ menu : this.$route.name, val : "N"})
          localStorage.setItem("SEO", confSeo)
          this.isMeta = false
        }

        if (this.isAdd) {
          this.row = {}
          setTimeout(() => {
            this.row = {}
            this.row.aa_is_active = "P";
            this.isSuggestTag = false
          }, 500)
        }

        if (!this.isList) {
          this.isSearch = false
          this.isFilter = false
          this.filter = {
            category: 0,
            status: 0,
            sort: 0,
            type: 0,
          }
        } else {
          this.filter = {
            category: 0,
            status: 0,
            filter: 0,
            sort: 0,
            type: 0,
            ...this.filter
          }
        }


      },
      'row.aa_title'(v) {
        if (this.isAdd) {
          this.row.aa_meta_title = v
        }
        this.row.aa_alt = v
      },
      'row.aat_tag'(v) {
        if(this.isAdd){
          this.row.aa_meta_keyword = v
        }
      },
      'row.aa_desc'(v) {
        if(this.isAdd && this.$route.params.pageSlug){
          this.row.aa_meta_description = v.replace(/<[^>]*>?/gm, '')
        }
      },
      'row_hero.ahi_title'(v) {
        this.row_hero.ahi_alt = v
      },
      // 'row.aa_slug'(v){
      //   this.row.aa_slug = this.cleanMultipleDash(v.toLowerCase().trim().replace(/ /g, '-').replace(/[^\w-]+/g, ''))
      // },
      '$route.params.pageSlug': function (v) {
        if (v === 'add') {
          setTimeout(() => {
            this.row = {}
            this.row.aa_is_active = "P";
            this.isSuggestTag = false
          }, 500)
        }
      },
    }
  }
</script>